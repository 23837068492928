import { useNavigate } from "react-router-dom";
import Layout from "./page2/Layout";

const Main = () => {

  const navigation = useNavigate()
  const pdam = localStorage.getItem("pdam");

  // window.addEventListener("unload", function () {
  //   localStorage.removeItem("pdam");
  // });

  return (
    <div className="h-full md:h-screen flex">
      {pdam ? <Layout /> : navigation("/", "_self")}
    </div>
  );
};

export default Main;
