import ReactApexChart from "react-apexcharts";
import React from "react";

const ChartRiwayat = (props) => {

  const pdam = props.data.map(pdams => {
    return pdams.pdam
  })

  const tagihan = props.data.map(totals => {
    return parseInt(totals.total_tagihan)
  })

  return (
    <div className="mt-5">
      <ReactApexChart
        options={{
          labels: pdam,
        }}
        series={tagihan}
        type="donut"
        height={252}
      />
    </div>
  );
};

export default ChartRiwayat;
