import { MapContainer, TileLayer, Marker, Tooltip } from "react-leaflet";
import { Icon } from "@iconify/react";
import { useState } from "react";
import { motion } from "framer-motion";
import L from "leaflet";

const Peta = (props) => {
  //epoch to human time
  function humanTime(epoch) {
    return new Date(epoch * 1).toLocaleString("id-ID");
  }

  const redIcon = new L.Icon({
    iconUrl:
      "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  const blueIcon = new L.Icon({
    iconUrl:
      "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  const length = props.ident.length * 18 * -1 - 42;
  // console.log(length);
  const [show, setShow] = useState(false);
  const [move, setMove] = useState(length);

  function mapCity() {
    setShow(!show);
    show ? setMove(length) : setMove(0);
  }

  return (
    <div className="w-full h-[500px] mt-[15px] drop-shadow-xl bg-white p-3 rounded-xl mb-5 relative overflow-hidden">
      <motion.div
        animate={{
          y: move,
        }}
        transition={{
          duration: 0.2,
        }}
        className={`absolute z-[99] bg-white w-[200px] h-auto rounded-b-lg left-[20%] md:left-[40%] pt-3 pb-3 px-2 opacity-80`}
      >
        <div className="flex justify-between px-6">
          <p className="font-semibold text-[12px]">PDAM</p>
          <p className="font-semibold text-[12px]">Jumlah</p>
        </div>
        <hr className="bg-slate-400 w-[160px] h-[1px] mt-1 mx-auto border-0" />
        {props.ident.map((dat, i) => (
          <div key={i} className="flex justify-between px-6">
            <p className="font-medium text-[12px]">{dat.pdam}</p>
            <p className="font-medium text-[12px]">{dat.jumlah}</p>
          </div>
        ))}

        <div
          className={`absolute left-1/2 -translate-x-1/2 cursor-pointer -bottom-12 ${
            show ? "rotate-180" : "rotate-0"
          }`}
          onClick={mapCity}
        >
          <Icon
            icon="material-symbols:arrow-drop-down-rounded"
            width="70"
            color="#221f20"
            className="animate-jump"
          />
        </div>
      </motion.div>
      <MapContainer
        key={Math.random()}
        className="w-full h-full z-0"
        center={[-7.24917, 112.75083]}
        zoom={5}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {props.pos.map((posisi, i) => (
          <Marker
            key={i}
            position={[posisi.lat * 1, posisi.lng * 1]}
            icon={posisi.status === "need-maintain" ? redIcon : blueIcon}
          >
            <Tooltip>
              <p>Nama: {posisi.nama}</p>
              <p>deveui: {posisi.devEui}</p>
              <p>Last Send: {humanTime(posisi.lastSend)}</p>
              <p>error: {posisi.error}</p>
              <p>delay: {posisi.delay}</p>
              <p>Posisi: {[posisi.lat * 1, " || ", posisi.lng * 1]}</p>
              <p>PDAM: {posisi.pdam}</p>
              <p>Kec: {posisi.kecamatan}</p>
              <p>Kel | Gol: {[posisi.kelompok, " | ", posisi.golongan]}</p>
              <p>Telp: {posisi.telp}</p>
            </Tooltip>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default Peta;
