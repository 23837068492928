import Logo from "./Logo";
import Footer from "./Footer";
import { Icon } from "@iconify/react";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigation = useNavigate();

  const [username, setUsername] = useState("");
  const [pass, setPass] = useState("");
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState({
    status: false,
    msg: "",
  });

  const usernameHandleChange = (val) => {
    setUsername(val.target.value);
  };

  const passHandleChange = (val) => {
    setPass(val.target.value);
  };

  // document.addEventListener("keydown", (event) => {
  //   if (event.code === "Enter") {
  //     submit();
  //   }
  // });

  const submit = async () => {
    setLoading(true);
    try {
      const s = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/lora/v1/data/cek-admin`,
        {
          pdam: username,
          pass: pass,
          super_admin: 1,
        },
        {
          timeout: 5000,
        }
      );

      setLoading(false);
      if (s.data.status === true) {
        localStorage.setItem("pdam", username);
        localStorage.setItem("token", s.data.token);
        navigation("/super-admin", "_self");
      } else {
        setErr({
          ...err,
          status: true,
          msg: "Periksa kembali username dan password anda",
        });
        setTimeout(() => {
          setErr({ ...err, status: false });
        }, 3000);
      }
    } catch (error) {
      setLoading(false);
      if (error.message.includes("timeout")) {
        setErr({ ...err, status: true, msg: "Periksa jaringan internet anda" });
        setTimeout(() => {
          setErr({ ...err, status: false });
        }, 3000);
      }
    }
  };

  return (
    <div className="w-screen h-screen font-poppins">
      <div className="w-screen h-screen absolute top-0 left-0 -z-20">
        <div
          className="w-screen h-screen bg-no-repeat bg-cover"
          style={{
            backgroundImage: `url(https://source.unsplash.com/1000x900/?water)`,
          }}
        >
          <div className="w-screen h-screen bg-white opacity-50" />
        </div>
      </div>
      <Logo />
      <div className="flex justify-center mt-16 md:mt-10">
        <div className="bg-white flex justify-center w-[300px] h-[400px] rounded-xl">
          <div>
            <p className="font-semibold text-[24px] text-center my-[50px] uppercase">
              zuper admin
            </p>
            <div className="flex flex-col">
              <label htmlFor="user" className="font-medium text-[13px] ml-2">
                Username
              </label>
              <div className="relative mt-1">
                <Icon
                  icon="mdi:user-circle-outline"
                  color="#474747"
                  className="absolute top-[5px] left-2"
                />
                <input
                  type="text"
                  placeholder="Masukkan username anda"
                  id="user"
                  className="border-b-2 focus:outline-none focus:border-[#158087] focus:border-b-2 text-[13px] pl-7 w-full placeholder:text-[#B4B4B4] py-1"
                  value={username}
                  onChange={usernameHandleChange}
                />
              </div>
              <label
                htmlFor="pass"
                className="font-medium text-[13px] mt-[23px] ml-2"
              >
                Password
              </label>
              <div className="relative mt-1">
                <Icon
                  icon="mdi:user-circle-outline"
                  color="#474747"
                  className="absolute top-[5px] left-2"
                />
                <input
                  type="password"
                  placeholder="Masukkan password anda"
                  id="pass"
                  className="border-b-2 focus:outline-none focus:border-teal-600 focus:border-b-2 text-[13px] pl-7 w-full placeholder:text-[#B4B4B4] py-1"
                  value={pass}
                  onChange={passHandleChange}
                />
              </div>
              <button
                className="uppercase bg-[#158087] h-[30px] w-[240px] rounded-[5px] font-medium text-[15px] text-white mt-[45px] hover:bg-[#1c98a1]"
                onClick={submit}
              >
                {loading ? "loading..." : "login"}
              </button>
              {err.status && (
                <p className="text-[10px] pt-2 z-50 text-red-500">{err.msg}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
