import ReactApexChart from "react-apexcharts";
import axios from "axios";
import React, { useState, useEffect } from "react";
import PuffLoader from "react-spinners/PuffLoader";

const Chart = () => {
  const x = new Date();
  const thisYear = x.getFullYear();

  const [revenue, setRevenue] = useState([]);
  const [penggunaan, setPenggunaan] = useState([]);
  const [bulan, setBulan] = useState([]);
  const [semuaTahun, setSemuaTahun] = useState([]);
  const [valOpt, setValOpt] = useState(thisYear);
  const [puffLoading, setPuffLoading] = useState(false);

  const namaBulan = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  async function fetching() {
    setPuffLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/lora/v1/data/super-admin-chart`,
        {
          tahun: valOpt,
        }
      )
      .then(function (response) {
        setPuffLoading(false)
        setRevenue(
          response.data.totalRevenuePerYear.map((tagih) => {
            return (tagih.total_tagihan / 1000000).toFixed(2);
          })
        );
        setBulan(
          response.data.totalRevenuePerYear.map((bln) => {
            return namaBulan[bln.bulan - 1];
          })
        );
        setPenggunaan(
          response.data.penggunaanAirPerBulan.map((pgn) => {
            return (pgn.total_penggunaan / 1000).toFixed(2);
          })
        );
        setSemuaTahun(response.data.semuaTahun);
      })
      .catch(function (error) {
        // handle error
        setPuffLoading(false)
        console.log(error);
      });
  }

  useEffect(() => {
    fetching();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valOpt]);

  const data = {
    series: [
      {
        name: "Revenue (jt)",
        type: "column",
        data: revenue,
      },
      {
        name: "Penggunaan air (m3)",
        type: "line",
        data: penggunaan,
      },
    ],
    options: {
      colors: ["#1a529c", "#19E5A0"],
      chart: {
        type: "line",
      },
      dataLabels: {
        enabled: true,
      },
      title: {
        text: "Konsumsi dan Pendapatan tahun",
        align: "left",
      },
      stroke: {
        width: [0, 4],
      },
      xaxis: {
        type: "string",
        categories: bulan,
      },
      yaxis: [
        {
          title: {
            text: "Revenue (jt)",
          },
        },
        {
          opposite: true,
          title: {
            text: "Penggunaan air (m3)",
          },
        },
      ],
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
  };

  const option = (val) => {
    console.log(val.target.value);
    setValOpt(val.target.value);
  };

  return (
    <div className="bg-white relative h-full w-full">
      <select
        className="border border-black cursor-pointer rounded-md px-2 absolute left-[280px] z-50 font-semibold focus:outline-none text-sm mt-[2px]"
        onChange={option}
        value={valOpt}
      >
        {semuaTahun.map((thn, i) => (
          <option key={i} value={thn}>
            {thn}
          </option>
        ))}
      </select>
      <div className="absolute left-[360px] z-50">
        <PuffLoader color="#38BDF8" size={25} loading={puffLoading} />
      </div>
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="area"
        height={320}
      />
    </div>
  );
};

export default Chart;
