import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import MetaUser from "./MetaUser";
import MetaProgresif from "./MetaProgresif";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Meta = () => {
  const navigation = useNavigate();

  const [menu, setMenu] = useState(true);
  const [opt, setOpt] = useState(true);
  const [flip, setFlip] = useState(true);
  const [nav, setNav] = useState(true);
  const [loading, setLoading] = useState(false);
  const [notif, setNotif] = useState({
    status: false,
    success: false,
    msg: "",
  });

  const [pdamList, setPdamList] = useState([]);
  const [kelList, setKelList] = useState([]);
  const [dataInput, setDataInput] = useState({
    pdam: "",
    kelGol: "",
    namaDev: "",
    telp: "",
    kec: "",
    koordinat: "",
    alamat: "",
  });
  const [newProgresif, setNewProgresif] = useState({
    kelompok: "",
    golongan: "",
    limit1: "0-10",
    limit2: "11-20",
    limit3: "21-30",
    limit4: ">30",
    tarif1: "",
    tarif2: "",
    tarif3: "",
    tarif4: "",
    pdam: "",
    beban_tetap: "",
    ukuran: "",
  });

  const [disableDevice, setDisableDevice] = useState(true);
  const [disableDeviceProgresif, setDisableDeviceProgresif] = useState(true);

  const active = { bg: "bg-[#38BDF8]", txt: "text-[#474747]" };
  const inActive = { bg: "bg-[#C2C2C2]", txt: "text-[#898989]" };

  // data input
  const handlePdamChange = (pdam) => {
    setDataInput({ ...dataInput, pdam: pdam.target.value });
    fetchingPdam(pdam.target.value, false);
  };
  const handleKelGolChange = (kelGol) => {
    setDataInput({ ...dataInput, kelGol: kelGol.target.value });
  };
  const handleNamaDevChange = (namaDev) => {
    setDataInput({ ...dataInput, namaDev: namaDev.target.value });
  };
  const handleTelpChange = (telp) => {
    setDataInput({ ...dataInput, telp: telp.target.value });
  };
  const handleKecChange = (kec) => {
    setDataInput({ ...dataInput, kec: kec.target.value });
  };
  const handleKoordinatChange = (koor) => {
    setDataInput({ ...dataInput, koordinat: koor.target.value });
  };

  // new device
  const handleSaveChange = async () => {
    setLoading(true);

    if (dataInput.namaDev.includes(",") && dataInput.koordinat.includes(",")) {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/lora/v1/data/super-new-device`,
          {
            pdam: dataInput.pdam,
            kelGol: dataInput.kelGol,
            namaDev: dataInput.namaDev,
            telp: dataInput.telp,
            kec: dataInput.kec,
            koordinat: dataInput.koordinat,
            askList: "hmm",
            alamat: dataInput.alamat,
            token: localStorage.getItem("token"),
          },
          { timeout: 5000 }
        )
        .then(function (response) {
          setLoading(false);
          // if token expired
          if (response.data.msg === "jwt expired") {
            localStorage.removeItem("token");
            localStorage.removeItem("pdam");
            navigation("/", "_self");
          } else {
            if (response.data.status !== "exist") {
              setDataInput((prevState) => ({
                ...prevState,
                namaDev: "",
                telp: "",
                koordinat: "",
                kec: "",
                alamat: "",
              }));
              setNotif({
                ...notif,
                status: true,
                success: true,
                msg: response.data.msg,
              });

              setTimeout(() => {
                setNotif({ ...notif, status: false, success: false });
              }, 3000);
            } else {
              setNotif({
                ...notif,
                status: true,
                msg: response.data.msg,
              });

              setTimeout(() => {
                setNotif({ ...notif, status: false });
              }, 3000);
            }
          }
        })
        .catch(function (err) {
          setLoading(false);
          if (err.message.includes("timeout")) {
            setNotif({
              ...notif,
              status: true,
              msg: "Periksa jaringan internet anda",
            });

            setTimeout(() => {
              setNotif({ ...notif, status: false });
            }, 3000);
          }
        });
    } else {
      setNotif({
        ...notif,
        status: true,
        msg: "Nama dan devEUI tidak benar",
      });

      setTimeout(() => {
        setNotif({ ...notif, status: false });
      }, 3000);
    }
  };

  // new progresif
  const handleSaveProgresif = async () => {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/lora/v1/data/super-new-progresif`,
        {
          golongan: newProgresif.golongan,
          kelompok: newProgresif.kelompok,
          limit1: newProgresif.limit1,
          limit2: newProgresif.limit2,
          limit3: newProgresif.limit3,
          limit4: newProgresif.limit4,
          tarif1: newProgresif.tarif1,
          tarif2: newProgresif.tarif2,
          tarif3: newProgresif.tarif3,
          tarif4: newProgresif.tarif4,
          beban_tetap: newProgresif.beban_tetap,
          pdam: newProgresif.pdam,
          ukuran: newProgresif.ukuran,
          token: localStorage.getItem("token"),
        },
        { timeout: 5000 }
      )
      .then(function (response) {
        setLoading(false);
        console.log(response.data);
        // if token expired
        if (response.data.msg === "jwt expired") {
          localStorage.removeItem("token");
          localStorage.removeItem("pdam");
          navigation("/", "_self");
        } else {
          if (response.data.status !== "exist") {
            setNewProgresif((thisNew) => ({
              ...thisNew,
              kelompok: "",
              golongan: "",
              tarif1: "",
              tarif2: "",
              tarif3: "",
              tarif4: "",
            }));
            setNotif({
              ...notif,
              status: true,
              success: true,
              msg: response.data.msg,
            });

            setTimeout(() => {
              setNotif({ ...notif, status: false, success: false });
            }, 3000);
          } else {
            setNotif({
              ...notif,
              status: true,
              msg: response.data.msg,
            });

            setTimeout(() => {
              setNotif({ ...notif, status: false });
            }, 3000);
          }
        }
      })
      .catch(function (error) {
        setLoading(false);
        if (error.message.includes("timeout")) {
          setNotif({
            ...notif,
            status: true,
            msg: "Periksa jaringan internet anda",
          });

          setTimeout(() => {
            setNotif({ ...notif, status: false });
          }, 3000);
        }
      });
  };

  // fetching awal
  async function fetchingPdam(x, y) {
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/lora/v1/data/super-new-device`, {
        kota: x,
        askList: y,
      })
      .then(function (response) {
        // console.log(response.data);
        if (y) {
          setPdamList(response.data);
        } else {
          setKelList(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    const isEmptyValue = Object.values(dataInput).some((value) => value === "");
    setDisableDevice(isEmptyValue);
    const isEmptyValueProgresif = Object.values(newProgresif).some(
      (value) => value === ""
    );
    setDisableDeviceProgresif(isEmptyValueProgresif);
  }, [dataInput, newProgresif]);

  useEffect(() => {
    fetchingPdam("", true);
  }, []);

  return pdamList === undefined ? (
    "loadings"
  ) : (
    <div className="w-full">
      {menu ? (
        <div className="w-full h-[600px] text-[14px] font-semibold flex justify-center items-center gap-2 md:gap-[50px]">
          <div
            className="h-[150px] w-[150px] md:h-[200px] md:w-[200px] bg-white rounded-lg shadow-lg hover:shadow-sm cursor-pointer -translate-y-1/4 md:translate-y-0 active:bg-slate-50"
            onClick={() => {
              setMenu(!menu);
              setOpt(true);
            }}
          >
            <div className="h-1/2 flex flex-col items-center justify-center relative">
              <p>Tambah data</p>
              <Icon
                icon="ic:round-plus"
                width="30"
                className="absolute bottom-0 translate-y-1/2"
              />
            </div>
          </div>
          <div
            className="h-[150px] w-[150px] md:h-[200px] md:w-[200px] bg-white rounded-lg shadow-lg hover:shadow-sm cursor-pointer -translate-y-1/4 md:translate-y-0 active:bg-slate-50"
            onClick={() => {
              setMenu(!menu);
              setOpt(false);
            }}
          >
            <div className="h-1/2 flex flex-col items-center justify-center relative">
              <p>Lihat semua data</p>
              <Icon
                icon="carbon:ibm-cloud-pak-manta-automated-data-lineage"
                width="26"
                className="absolute bottom-0 translate-y-1/2"
              />
            </div>
          </div>
        </div>
      ) : opt ? (
        // tambah data
        <div className="w-full pb-20 text-[14px] font-semibold flex justify-center gap-2 md:gap-[50px] md:bg-white">
          <div className="w-full md:w-[460px]">
            {/* head */}
            <div className="mt-16 md:mt-[35px] text-[15px] w-full font-semibold flex justify-between gap-5">
              <div
                className={`text-center duration-1000 ${
                  flip ? "text-[#474747]" : "text-[#cacaca]"
                }`}
              >
                <div className="md:flex gap-1">
                  <p>Tambah device </p>
                  <p>baru</p>
                </div>
              </div>
              <Icon
                icon="material-symbols:arrow-circle-left-rounded"
                width="30"
                color="#474747"
                className={`cursor-pointer duration-500 ${
                  flip ? "rotate-0" : "rotate-180"
                }`}
                onClick={() => setFlip(!flip)}
              />
              <div
                className={`text-center duration-1000 ${
                  flip ? "text-[#cacaca]" : "text-[#474747]"
                }`}
              >
                <div className="md:flex gap-1">
                  <p>Tambah data</p>
                  <p>progresif</p>
                </div>
              </div>
            </div>
            {/* tambah data form */}
            {flip ? (
              <div className="mt-12 w-full text-[#474747]">
                <div className="flex gap-[20px] w-full">
                  <div className="flex flex-col">
                    <label htmlFor="kota" className="pl-2">
                      PDAM
                    </label>
                    <select
                      type="text"
                      name="kota"
                      className="w-[165px] md:h-[30px] md:w-[220px] md:bg-[#D9D9D9] outline-slate-500 pl-1 font-normal"
                      onChange={handlePdamChange}
                    >
                      <option
                        value="pilih"
                        className="text-[#8F8F8F] text-[10px]"
                      >
                        PDAM user
                      </option>
                      {pdamList.map((pdam, i) => (
                        <option key={i} value={pdam.kota}>
                          {pdam.kota}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="kelompok" className="pl-2">
                      Kelompok
                    </label>
                    <select
                      type="text"
                      name="kelompok"
                      className="w-[165px] md:h-[30px] md:w-[220px] md:bg-[#D9D9D9] outline-slate-500 pl-1 font-normal"
                      onChange={handleKelGolChange}
                    >
                      <option className="text-[#8F8F8F] text-[10px] ">
                        Kelompok PDAM
                      </option>
                      {kelList.map((kel, i) => (
                        <option key={i} value={[kel.kelompok, kel.golongan]}>
                          Kelompok {kel.kelompok} Golongan {kel.golongan}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex gap-[20px] w-full mt-5">
                  <div className="flex flex-col">
                    <label htmlFor="nama" className="pl-2">
                      Nama, DevEui
                    </label>
                    <input
                      type="text"
                      name="nama"
                      className="w-[165px] md:h-[30px] md:w-[220px] md:bg-[#D9D9D9] outline-slate-500 pl-2 font-normal placeholder:text-[#8F8F8F] placeholder:text-[10px] placeholder:font-semibold"
                      placeholder="Nama pengguna, devEui"
                      onChange={handleNamaDevChange}
                      value={dataInput.namaDev}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="dev" className="pl-2">
                      Telp
                    </label>
                    <input
                      type="number"
                      name="dev"
                      className="w-[165px] md:h-[30px] md:w-[220px] md:bg-[#D9D9D9] outline-slate-500 pl-2 font-normal placeholder:text-[#8F8F8F] placeholder:text-[10px] placeholder:font-semibold"
                      placeholder="Nomor telefon pengguna"
                      onChange={handleTelpChange}
                      value={dataInput.telp}
                    />
                  </div>
                </div>
                <div className="flex gap-[20px] w-full mt-5">
                  <div className="flex flex-col">
                    <label htmlFor="kec" className="pl-2">
                      Kecamatan
                    </label>
                    <input
                      type="text"
                      name="kec"
                      className="w-[165px] md:h-[30px] md:w-[220px] md:bg-[#D9D9D9] outline-slate-500 pl-2 font-normal placeholder:text-[#8F8F8F] placeholder:text-[10px] placeholder:font-semibold"
                      placeholder="kecamatan user"
                      onChange={handleKecChange}
                      value={dataInput.kec}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="koordinat" className="pl-2">
                      Koordinat
                    </label>
                    <input
                      type="text"
                      name="koordinat"
                      className="w-[165px] md:h-[30px] md:w-[220px] md:bg-[#D9D9D9] outline-slate-500 pl-2 font-normal placeholder:text-[#8F8F8F] placeholder:text-[10px] placeholder:font-semibold"
                      placeholder="lokasi user (garis-lintang, garis-bujur)"
                      onChange={handleKoordinatChange}
                      value={dataInput.koordinat}
                    />
                  </div>
                </div>
                <div className="w-full mt-5">
                  <div className="flex flex-col">
                    <label htmlFor="alamat" className="pl-2">
                      Alamat
                    </label>
                    <textarea
                      placeholder="Alamat user"
                      className="pt-2 bg-[#D9D9D9] outline-slate-500 pl-2 font-normal placeholder:text-[#8F8F8F] placeholder:text-[10px] placeholder:font-semibold"
                      onChange={(val) =>
                        setDataInput({ ...dataInput, alamat: val.target.value })
                      }
                      value={dataInput.alamat}
                    />
                  </div>
                </div>
                <button
                  className="mt-10 bg-[#158087] hover:bg-[#2e979e] text-white h-[40px] uppercase text-center font-semibold text-[16px] w-full flex items-center justify-center gap-1 disabled:bg-[#cbcbcb] disabled:hover:bg-[#cbcbcb]"
                  onClick={handleSaveChange}
                  disabled={disableDevice}
                >
                  <Icon icon="material-symbols:save-outline" width="22" />
                  <p>{loading ? "menyimpan..." : "simpan"}</p>
                </button>
                <p
                  className="w-full text-red-500 text-center font-normal underline mt-3 cursor-pointer active:scale-105"
                  onClick={() => setMenu(!menu)}
                >
                  cancel
                </p>
              </div>
            ) : (
              <div className="mt-12 w-full text-[#474747]">
                <div className="flex gap-[20px] w-full mb-[30px]">
                  <div className="flex flex-col">
                    <label htmlFor="kelompok" className="pl-2">
                      Kelompok
                    </label>
                    <input
                      type="number"
                      name="kelompok"
                      className="w-[165px] md:h-[30px] md:w-[220px] md:bg-[#D9D9D9] outline-slate-500 pl-2 font-normal placeholder:text-[#8F8F8F] placeholder:text-[10px] placeholder:font-semibold"
                      placeholder="Kelompok user"
                      onChange={(e) =>
                        setNewProgresif({
                          ...newProgresif,
                          kelompok: e.target.value,
                        })
                      }
                      value={newProgresif.kelompok}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="golongan" className="pl-2">
                      Golongan
                    </label>
                    <input
                      type="number"
                      name="golongan"
                      className="w-[165px] md:h-[30px] md:w-[220px] md:bg-[#D9D9D9] outline-slate-500 pl-2 font-normal placeholder:text-[#8F8F8F] placeholder:text-[10px] placeholder:font-semibold"
                      placeholder="Golongan user"
                      onChange={(e) =>
                        setNewProgresif({
                          ...newProgresif,
                          golongan: e.target.value,
                        })
                      }
                      value={newProgresif.golongan}
                    />
                  </div>
                </div>
                <div className="flex gap-[20px] w-full mb-[30px] flex-col md:flex-row">
                  <div className="flex gap-[20px] ">
                    <div>
                      <label htmlFor="limit1" className="pl-2">
                        Limit1
                      </label>
                      <input
                        type="text"
                        name="limit1"
                        className="w-[165px] md:h-[30px] md:w-[100px] md:bg-[#D9D9D9] outline-slate-500 pl-2 font-normal placeholder:text-[#8F8F8F] placeholder:text-[10px] placeholder:font-semibold"
                        placeholder="0-10"
                        onChange={(e) =>
                          setNewProgresif({
                            ...newProgresif,
                            limit1: e.target.value,
                          })
                        }
                        value={newProgresif.limit1}
                      />
                    </div>
                    <div>
                      <label htmlFor="limit2" className="pl-2">
                        Limit2
                      </label>
                      <input
                        type="text"
                        name="limit2"
                        className="w-[165px] md:h-[30px] md:w-[100px] md:bg-[#D9D9D9] outline-slate-500 pl-2 font-normal placeholder:text-[#8F8F8F] placeholder:text-[10px] placeholder:font-semibold"
                        placeholder="11-20"
                        onChange={(e) =>
                          setNewProgresif({
                            ...newProgresif,
                            limit2: e.target.value,
                          })
                        }
                        value={newProgresif.limit2}
                      />
                    </div>
                  </div>
                  <div className="flex gap-[20px]">
                    <div>
                      <label htmlFor="limit3" className="pl-2">
                        Limit3
                      </label>
                      <input
                        type="text"
                        name="limit3"
                        className="w-[165px] md:h-[30px] md:w-[100px] md:bg-[#D9D9D9] outline-slate-500 pl-2 font-normal placeholder:text-[#8F8F8F] placeholder:text-[10px] placeholder:font-semibold"
                        placeholder="21-30"
                        onChange={(e) =>
                          setNewProgresif({
                            ...newProgresif,
                            limit3: e.target.value,
                          })
                        }
                        value={newProgresif.limit3}
                      />
                    </div>
                    <div>
                      <label htmlFor="limit4" className="pl-2">
                        Limit4
                      </label>
                      <input
                        type="text"
                        name="limit4"
                        className="w-[165px] md:h-[30px] md:w-[100px] md:bg-[#D9D9D9] outline-slate-500 pl-2 font-normal placeholder:text-[#8F8F8F] placeholder:text-[10px] placeholder:font-semibold"
                        placeholder=">30"
                        onChange={(e) =>
                          setNewProgresif({
                            ...newProgresif,
                            limit4: e.target.value,
                          })
                        }
                        value={newProgresif.limit4}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex gap-[20px] w-full mb-[30px] flex-col md:flex-row">
                  <div className="flex gap-[20px]">
                    <div>
                      <label htmlFor="tarif1" className="pl-2">
                        Tarif1
                      </label>
                      <input
                        type="number"
                        name="tarif1"
                        className="w-[165px] md:h-[30px] md:w-[100px] md:bg-[#D9D9D9] outline-slate-500 pl-2 font-normal placeholder:text-[#8F8F8F] placeholder:text-[10px] placeholder:font-semibold"
                        placeholder="tarif pada limit1"
                        onChange={(e) =>
                          setNewProgresif({
                            ...newProgresif,
                            tarif1: e.target.value,
                          })
                        }
                        value={newProgresif.tarif1}
                      />
                    </div>
                    <div>
                      <label htmlFor="tarif2" className="pl-2">
                        Tarif2
                      </label>
                      <input
                        type="number"
                        name="tarif2"
                        className="w-[165px] md:h-[30px] md:w-[100px] md:bg-[#D9D9D9] outline-slate-500 pl-2 font-normal placeholder:text-[#8F8F8F] placeholder:text-[10px] placeholder:font-semibold"
                        placeholder="tarif pada limit2"
                        onChange={(e) =>
                          setNewProgresif({
                            ...newProgresif,
                            tarif2: e.target.value,
                          })
                        }
                        value={newProgresif.tarif2}
                      />
                    </div>
                  </div>
                  <div className="flex gap-[20px]">
                    <div>
                      <label htmlFor="tarif3" className="pl-2">
                        Tarif3
                      </label>
                      <input
                        type="number"
                        name="tarif3"
                        className="w-[165px] md:h-[30px] md:w-[100px] md:bg-[#D9D9D9] outline-slate-500 pl-2 font-normal placeholder:text-[#8F8F8F] placeholder:text-[10px] placeholder:font-semibold"
                        placeholder="tarif pada limit3"
                        onChange={(e) =>
                          setNewProgresif({
                            ...newProgresif,
                            tarif3: e.target.value,
                          })
                        }
                        value={newProgresif.tarif3}
                      />
                    </div>
                    <div>
                      <label htmlFor="tarif4" className="pl-2">
                        Tarif4
                      </label>
                      <input
                        type="number"
                        name="tarif4"
                        className="w-[165px] md:h-[30px] md:w-[100px] md:bg-[#D9D9D9] outline-slate-500 pl-2 font-normal placeholder:text-[#8F8F8F] placeholder:text-[10px] placeholder:font-semibold"
                        placeholder="tarif pada limit4"
                        onChange={(e) =>
                          setNewProgresif({
                            ...newProgresif,
                            tarif4: e.target.value,
                          })
                        }
                        value={newProgresif.tarif4}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex gap-[20px] w-full mb-[30px] ">
                  <div className="flex gap-[20px]">
                    <div>
                      <label htmlFor="kota" className="pl-2">
                        PDAM
                      </label>
                      <input
                        type="text"
                        name="kota"
                        className="w-[165px] md:h-[30px] md:w-[220px] md:bg-[#D9D9D9] outline-slate-500 pl-2 font-normal placeholder:text-[#8F8F8F] placeholder:text-[10px] placeholder:font-semibold"
                        placeholder="nama PDAM"
                        onChange={(e) =>
                          setNewProgresif({
                            ...newProgresif,
                            pdam: e.target.value,
                          })
                        }
                        value={newProgresif.pdam}
                      />
                    </div>
                  </div>
                  <div className="flex gap-[20px] flex-col md:flex-row">
                    <div>
                      <label htmlFor="beban" className="pl-2">
                        Beban tetap
                      </label>
                      <input
                        type="number"
                        name="beban"
                        className="w-[165px] md:h-[30px] md:w-[100px] md:bg-[#D9D9D9] outline-slate-500 pl-2 font-normal placeholder:text-[#8F8F8F] placeholder:text-[10px] placeholder:font-semibold"
                        placeholder="tarif tetap/bulan"
                        onChange={(e) =>
                          setNewProgresif({
                            ...newProgresif,
                            beban_tetap: e.target.value,
                          })
                        }
                        value={newProgresif.beban_tetap}
                      />
                    </div>
                    <div>
                      <label htmlFor="ukuran" className="pl-2">
                        Ukuran
                      </label>
                      <input
                        type="text"
                        name="ukuran"
                        className="w-[165px] md:h-[30px] md:w-[100px] md:bg-[#D9D9D9] outline-slate-500 pl-2 font-normal placeholder:text-[#8F8F8F] placeholder:text-[10px] placeholder:font-semibold"
                        placeholder="diameter pipa"
                        onChange={(e) =>
                          setNewProgresif({
                            ...newProgresif,
                            ukuran: e.target.value,
                          })
                        }
                        value={newProgresif.ukuran}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center md:flex-row">
                  <button
                    className="bg-[#158087] hover:bg-[#2e979e] text-white h-[40px] uppercase text-center font-semibold text-[16px] w-full md:w-[340px] flex items-center justify-center gap-1 flex-shrink-0 disabled:bg-[#cbcbcb] disabled:hover:bg-[#cbcbcb]"
                    onClick={handleSaveProgresif}
                    disabled={disableDeviceProgresif}
                  >
                    <Icon icon="material-symbols:save-outline" width="22" />
                    <p>{loading ? "menyimpan..." : "simpan"}</p>
                  </button>
                  <p
                    className="w-full text-red-500 text-center font-normal underline cursor-pointer active:scale-105 md:ml-3 mt-3 md:mt-0"
                    onClick={() => setMenu(!menu)}
                  >
                    cancel
                  </p>
                </div>
              </div>
            )}
            {notif.status && (
              <p
                className={`${
                  notif.success ? "text-green-400" : "text-red-400"
                } font-semibold pt-3`}
              >
                {notif.msg}
              </p>
            )}
          </div>
        </div>
      ) : (
        // lhat semua data
        <div className="w-full h-full text-[14px] font-semibold flex flex-col mt-2">
          {/* menu */}
          <div className="w-full h-[50px] pr-[30px] flex gap-[40px] justify-end relative pt-5">
            <div className="absolute top-0 right-0 w-full">
              <div
                className={`absolute w-[10px] h-[10px] top-1 right-[310px] ${
                  nav ? active.bg : inActive.bg
                } rounded-full border-2 border-[#727272] z-50 duration-500`}
              />
              <div
                className={`absolute w-[10px] h-[10px] top-1 right-[185px] ${
                  nav ? inActive.bg : active.bg
                } rounded-full border-2 border-[#727272] z-50 duration-500`}
              />
              <hr className="w-[125px] border-2 border-[#b0b0b0] absolute top-[7px] right-[188px]" />
            </div>
            <p
              className={`${
                nav ? active.txt : inActive.txt
              } cursor-pointer duration-500`}
              onClick={() => setNav(!nav)}
            >
              Data User
            </p>
            <p
              className={`${
                nav ? active.txt : inActive.txt
              } cursor-pointer mr-5 duration-500`}
              onClick={() => setNav(!nav)}
            >
              Data Progresif
            </p>
            <p
              className="cursor-pointer text-[#898989] hover:text-[#474747] duration-500 pl-3"
              onClick={() => setMenu(!menu)}
            >
              Back
            </p>
          </div>
          {/* table container */}
          {nav ? <MetaUser /> : <MetaProgresif />}
        </div>
      )}
    </div>
  );
};

export default Meta;
