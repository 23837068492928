import { Route, Routes } from 'react-router-dom'
import Home from "./components/Home";
import Main from './components/Main';

function App() {
  return (
    <Routes>
      <Route>
        <Route path='/' element={<Home />} />
        <Route path='/super-admin' element={<Main />} />
      </Route>
    </Routes>
  );
}

export default App;
