import jsPDF from "jspdf";
import angkaTerbilang from "@develoka/angka-terbilang-js";

import its from "../../assets/LogoIts.png";
import surya from "../../assets/pdam.jpeg";
import lunas from "../../assets/lunas.png";
import belum_lunas from "../../assets/belum_lunas.png";

const Slip = (props) => {

  const namaBulan = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];
  const idr = (number) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(number);
  };
  function capitalizeWords(input) {
    return input.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  const doc = new jsPDF();

  doc.setFont("times", "normal");
  doc.setFontSize(12);

  // bagian header------------------------------
  // garis atas
  doc.line(20, 20, 200, 20);

  doc.line(20, 55, 20, 20);
  doc.line(65, 55, 65, 20);
  doc.line(155, 55, 155, 20);
  doc.line(200, 55, 200, 20);

  doc.line(65, 34, 155, 34);
  doc.line(110, 55, 110, 34);
  doc.line(65, 42, 155, 42);

  doc.addImage(its, "JPEG", 24, 27, 38, 20);
  doc.addImage(surya, "JPEG", 162, 23, 30, 30);

  doc.setFont("times", "bold");
  doc.text("Bulan", 80, 40);
  doc.text("Nomor Tagihan", 71, 50);

  doc.setFont("times", "normal");
  doc.text(
    namaBulan[props.bill.bulan - 1] + " " + props.bill.tahun,
    112,
    40
  );
  doc.text(props.bill.deveui + " -", 112, 48);
  doc.text(props.bill.created_at, 112, 53);

  doc.setFontSize(20);
  doc.setFont("times", "bold");
  doc.text("Bukti Tagihan", 90, 29);

  doc.line(20, 55, 200, 55);

  //identitas--------------------------
  doc.setFont("times", "bold");
  doc.setFontSize(12);

  doc.text("Nomor Pelanggan   :", 25, 70);
  doc.text("Nama Pelanggan    :", 25, 78);
  doc.text("Alamat Pelanggan  :", 25, 86);

  doc.text("Katergori Pelanggan  :", 112, 70);
  doc.text("Penggunaan                :", 112, 78);
  // doc.text("Alamat Pelanggan      :", 112, 86);

  doc.setFont("times", "normal");

  doc.text(props.bill.deveui, 65, 70);
  doc.text(props.identitas.nama, 65, 78);
  doc.text(props.identitas.kecamatan, 65, 86);

  doc.text(
    "Kel " +
      props.identitas.kelompok +
      " Gol " +
      props.identitas.golongan,
    158,
    70
  );
  const a = (props.bill.penggunaan * 0.001).toLocaleString("id-ID");
  doc.text(a + " m3", 158, 78);
  // doc.text("Alamat Pelanggan", 158, 86);

  //rincian--------------------------------------
  doc.setFont("times", "bold");
  doc.text("RINCIAN TAGIHAN REKENING", 25, 106);

  //container table
  doc.setFillColor("#0ff");
  doc.rect(20, 110, 180, 8, "F");

  doc.line(20, 110, 200, 110);
  doc.line(20, 110, 20, 210);
  doc.line(200, 110, 200, 210);
  doc.line(20, 210, 200, 210);

  //inside line
  doc.line(20, 118, 200, 118);
  doc.line(28, 110, 28, 182); // garis nomor
  doc.line(110, 110, 110, 118);
  doc.line(110, 126, 110, 190); //garis tengah
  doc.line(157, 134, 157, 166); //garis tengah biaya

  //head
  doc.setFont("times", "normal");
  doc.text("No", 21, 116);
  doc.text("Nama Item", 62, 116);
  doc.text("Biaya", 152, 116);

  //1
  doc.text("1", 22, 124);
  doc.line(28, 126, 200, 126);
  doc.setFont("times", "bold");
  doc.text("Biaya Pemakaian Air", 30, 124);
  doc.setFont("times", "normal");

  doc.text("- Jumlah Pemakaian", 30, 131);
  doc.line(28, 134, 200, 134);
  // doc.text("m3", 112, 131);
  doc.text(a + " m3", 197, 131, null, null, "right");

  doc.text("- Tarif " + props.progresif.limit1 + " m3", 30, 139);
  doc.line(28, 142, 200, 142);
  doc.text(idr(props.progresif.tarif1), 112, 139);
  doc.text(props.hargaPerm3.h1.toLocaleString('id-ID'), 197, 139, null, null, "right");

  doc.text("- Tarif " + props.progresif.limit2 + " m3", 30, 147);
  doc.line(28, 150, 200, 150);
  doc.text(idr(props.progresif.tarif2), 112, 147);
  doc.text(props.hargaPerm3.h2.toLocaleString('id-ID'), 197, 147, null, null, "right");

  doc.text("- Tarif " + props.progresif.limit3 + " m3", 30, 155);
  doc.line(28, 158, 200, 158);
  doc.text(idr(props.progresif.tarif3), 112, 155);
  doc.text(props.hargaPerm3.h3.toLocaleString('id-ID'), 197, 155, null, null, "right");

  doc.text("- Tarif " + props.progresif.limit4 + " m3", 30, 163);
  doc.line(20, 166, 200, 166);
  doc.text(idr(props.progresif.tarif4), 112, 163);
  doc.text(props.hargaPerm3.h4.toLocaleString('id-ID'), 197, 163, null, null, "right");

  //2
  doc.text("2", 22, 172);
  doc.line(20, 174, 200, 174);
  doc.setFont("times", "bold");
  doc.text("Biaya Iuran Wajib (Abonemen)", 30, 172);
  doc.setFont("times", "normal");

  doc.text("Rp", 112, 172);
  doc.text(props.progresif.beban_tetap.toLocaleString('id-ID'), 197, 172, null, null, "right");

  //3
  doc.text("3", 22, 180);
  doc.line(20, 182, 200, 182);
  doc.setFont("times", "bold");
  doc.text("Biaya Denda", 30, 180);
  doc.setFont("times", "normal");

  doc.text("Rp", 112, 180);
  doc.text("---", 197, 180, null, null, "right");

  //total

  doc.line(20, 190, 200, 190);
  doc.setFont("times", "bold");
  doc.text("Total Tagihan", 52, 188);

  doc.text("Rp", 112, 188);
  doc.text(idr(props.bill.tagihan), 197, 188, null, null, "right");

  //terbilang
  doc.setFont("times", "normal");
  doc.text("Terbilang", 102, 196);
  doc.setFont("times", "bold");

  doc.text(
    capitalizeWords(angkaTerbilang(props.bill.tagihan)) + " Rupiah",
    110,
    205,
    null,
    null,
    "center"
  );

  //footer
  doc.text("Catatan", 25, 220);

  doc.setFont("times", "normal");

  doc.text("Jatuh tempo pembayaran setiap tanggal 20", 25, 226);

  doc.text(capitalizeWords(props.bill.pdam) + ",", 125, 226);
  doc.text(
    namaBulan[props.bill.bulan - 1] + " " + props.bill.tahun,
    150,
    226
  );

  doc.text("ITS", 143, 256);
  
  doc.setFont("helvetica", "bold");
  doc.setFontSize(8);
  doc.text("Powered by: SIAB INDONESIA", 90, 290);

  // lunas / belum lunas
  props.bill.status === "unpaid"
    ? doc.addImage(belum_lunas, "JPEG", 50, 50, 120, 200)
    : doc.addImage(lunas, "JPEG", 50, 50, 120, 200);

  // doc.save("sample.pdf");

  // Generate the PDF blob
  const pdfBlob = doc.output("blob");
  const pdfUrl = URL.createObjectURL(pdfBlob);
  window.open(pdfUrl, "_blank");
};

export default Slip;
