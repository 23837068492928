import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import axios from "axios";
import PuffLoader from "react-spinners/PuffLoader";
import { useNavigate } from "react-router-dom";

const MetaProgresif = () => {
  const navigation = useNavigate();
  const [filter, setFilter] = useState(false);

  const [valPdam, setValPdam] = useState("");
  const [val, setVal] = useState([]);
  const [loading, setLoading] = useState(false);

  const [dataProgresif, setDataProgresif] = useState([]);
  const [listPdam, setListPdam] = useState([]);
  const [editProgresifData, setEditProgresifData] = useState({
    status: false,
    index: -1,
  });
  const [copyProgresif, setCopyProgresif] = useState([]);

  const radioPdam = (value) => {
    setValPdam(value.target.value);
  };

  const controlFilter = () => {
    setFilter(!filter);
    if (filter === true) {
      setVal(valPdam);
      fetchingData();
    }
  };

  async function fetchingData() {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/lora/v1/data/super-meta-data`, {
        pdam: valPdam,
        askFor: "progresif",
      })
      .then(function (response) {
        setDataProgresif(response.data);
        setCopyProgresif(response.data);
        setLoading(false);

        // untuk mendapatkan indek edit data
        let arr = [];
        response.data.map((x, i) => arr.push({ status: false, index: i }));
        setEditProgresifData(arr);
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  // get list pdam
  useEffect(() => {
    async function getListPdam() {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/lora/v1/data/super-new-device`,
          {
            askList: true,
          }
        )
        .then(function (response) {
          setListPdam(response.data);
        })
        .catch(function (err) {
          console.error(err);
        });
    }
    getListPdam();
  }, []);

  // update data
  const handleEdit = async (i) => {
    let dataWithToken = {
      ...copyProgresif[i],
      token: localStorage.getItem("token"),
    };

    if (editProgresifData[i].status === true) {
      const update = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/lora/v1/data/super-update-progresif`,
        dataWithToken
      );

      if (update.data.msg === "jwt expired") {
        localStorage.removeItem("token");
        localStorage.removeItem("pdam");
        navigation("/", "_self");
      } else {
        if (update.data.updating.rowCount === 1) {
          fetchingData();
        }
      }
    }

    setEditProgresifData({
      ...editProgresifData,
      [i]: {
        status: !editProgresifData[i].status,
        index: i,
      },
    });
  };

  return (
    <div className="bg-white h-full w-full rounded-lg">
      <div className="w-full h-[54px] rounded-t-lg flex justify-between px-[15px] items-center">
        <div className="flex gap-[15px] text-[12px] font-medium text-[#898989] relative">
          <div
            className="h-[30px] w-auto border border-[#38BDF8] rounded-lg flex items-center px-1 relative cursor-pointer active:border-white"
            onClick={controlFilter}
          >
            <Icon
              icon="material-symbols:filter-alt-outline"
              color="#38BDF8"
              width="18"
            />
            <input
              type="text"
              placeholder="Cari PDAM"
              readOnly
              value={val}
              className="w-[72px] outline-none cursor-pointer"
            />
          </div>
          <div
            className={`w-[100px] h-auto bg-white absolute top-9 ${
              filter ? "left-0" : "-left-40"
            } duration-500 p-2 rounded-lg border border-[#38BDF8] cursor-default`}
          >
            <p className="text-[10px] text-slate-400 border-b border-slate-400 mb-1">
              PDAM
            </p>
            {listPdam.map((d, i) => (
              <label key={i} className="flex gap-1 hover:text-[#38BDF8]">
                <input
                  type="radio"
                  name="pdam"
                  value={d.kota}
                  onChange={radioPdam}
                />
                {d.kota}
              </label>
            ))}
          </div>
        </div>
        <PuffLoader color="#38BDF8" size={40} loading={loading} />
      </div>
      <table className="w-full">
        <thead>
          <tr className="bg-[#DCF4FF] h-[40px] text-left border-b border-[#AAE4FE] text-[#474747]">
            <th className="pl-[10px] border-r-2">No</th>
            <th className="pl-1">Kelompok</th>
            <th>Golongan</th>
            <th>Limit 1</th>
            <th>Limit 2</th>
            <th>Limit 3</th>
            <th>Limit 4</th>
            <th>Tarif 1</th>
            <th>Taris 2</th>
            <th>Taris 3</th>
            <th>Taris 4</th>
            <th>Beban Tetap</th>
            <th>Ukuran Pipa</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {dataProgresif.map((data, i) => (
            <tr
              key={i}
              className="h-[40px] border-b border-[#AAE4FE] font-medium text-[#898989]"
            >
              <td className="pl-[15px] border-r-2 font-bold">{i + 1}</td>
              <td className="pl-[15px]">
                {editProgresifData[i].status ? (
                  <input
                    type="text"
                    value={copyProgresif[i].kelompok}
                    onChange={(val) =>
                      setCopyProgresif((prevCopyProgresif) => {
                        const updatedCopyProgresif = [...prevCopyProgresif];
                        updatedCopyProgresif[i] = {
                          ...updatedCopyProgresif[i],
                          kelompok: val.target.value,
                        };
                        return updatedCopyProgresif;
                      })
                    }
                    className="w-16"
                  />
                ) : (
                  data.kelompok
                )}
              </td>
              <td>
                {editProgresifData[i].status ? (
                  <input
                    type="text"
                    value={copyProgresif[i].golongan}
                    onChange={(val) =>
                      setCopyProgresif((prevCopyProgresif) => {
                        const updatedCopyProgresif = [...prevCopyProgresif];
                        updatedCopyProgresif[i] = {
                          ...updatedCopyProgresif[i],
                          golongan: val.target.value,
                        };
                        return updatedCopyProgresif;
                      })
                    }
                    className="w-16"
                  />
                ) : (
                  data.golongan
                )}
              </td>
              <td>
                {editProgresifData[i].status ? (
                  <input
                    type="text"
                    value={copyProgresif[i].limit1}
                    onChange={(val) =>
                      setCopyProgresif((prevCopyProgresif) => {
                        const updatedCopyProgresif = [...prevCopyProgresif];
                        updatedCopyProgresif[i] = {
                          ...updatedCopyProgresif[i],
                          limit1: val.target.value,
                        };
                        return updatedCopyProgresif;
                      })
                    }
                    className="w-16"
                  />
                ) : (
                  data.limit1
                )}
              </td>
              <td>
                {editProgresifData[i].status ? (
                  <input
                    type="text"
                    value={copyProgresif[i].limit2}
                    onChange={(val) =>
                      setCopyProgresif((prevCopyProgresif) => {
                        const updatedCopyProgresif = [...prevCopyProgresif];
                        updatedCopyProgresif[i] = {
                          ...updatedCopyProgresif[i],
                          limit2: val.target.value,
                        };
                        return updatedCopyProgresif;
                      })
                    }
                    className="w-16"
                  />
                ) : (
                  data.limit2
                )}
              </td>
              <td>
                {editProgresifData[i].status ? (
                  <input
                    type="text"
                    value={copyProgresif[i].limit3}
                    onChange={(val) =>
                      setCopyProgresif((prevCopyProgresif) => {
                        const updatedCopyProgresif = [...prevCopyProgresif];
                        updatedCopyProgresif[i] = {
                          ...updatedCopyProgresif[i],
                          limit3: val.target.value,
                        };
                        return updatedCopyProgresif;
                      })
                    }
                    className="w-16"
                  />
                ) : (
                  data.limit3
                )}
              </td>
              <td>
                {editProgresifData[i].status ? (
                  <input
                    type="text"
                    value={copyProgresif[i].limit4}
                    onChange={(val) =>
                      setCopyProgresif((prevCopyProgresif) => {
                        const updatedCopyProgresif = [...prevCopyProgresif];
                        updatedCopyProgresif[i] = {
                          ...updatedCopyProgresif[i],
                          limit4: val.target.value,
                        };
                        return updatedCopyProgresif;
                      })
                    }
                    className="w-16"
                  />
                ) : (
                  data.limit4
                )}
              </td>
              <td>
                {editProgresifData[i].status ? (
                  <input
                    type="text"
                    value={copyProgresif[i].tarif1}
                    onChange={(val) =>
                      setCopyProgresif((prevCopyProgresif) => {
                        const updatedCopyProgresif = [...prevCopyProgresif];
                        updatedCopyProgresif[i] = {
                          ...updatedCopyProgresif[i],
                          tarif1: val.target.value,
                        };
                        return updatedCopyProgresif;
                      })
                    }
                    className="w-16"
                  />
                ) : (
                  data.tarif1
                )}
              </td>
              <td>
                {editProgresifData[i].status ? (
                  <input
                    type="text"
                    value={copyProgresif[i].tarif2}
                    onChange={(val) =>
                      setCopyProgresif((prevCopyProgresif) => {
                        const updatedCopyProgresif = [...prevCopyProgresif];
                        updatedCopyProgresif[i] = {
                          ...updatedCopyProgresif[i],
                          tarif2: val.target.value,
                        };
                        return updatedCopyProgresif;
                      })
                    }
                    className="w-16"
                  />
                ) : (
                  data.tarif2
                )}
              </td>
              <td>
                {editProgresifData[i].status ? (
                  <input
                    type="text"
                    value={copyProgresif[i].tarif3}
                    onChange={(val) =>
                      setCopyProgresif((prevCopyProgresif) => {
                        const updatedCopyProgresif = [...prevCopyProgresif];
                        updatedCopyProgresif[i] = {
                          ...updatedCopyProgresif[i],
                          tarif3: val.target.value,
                        };
                        return updatedCopyProgresif;
                      })
                    }
                    className="w-16"
                  />
                ) : (
                  data.tarif3
                )}
              </td>
              <td>
                {editProgresifData[i].status ? (
                  <input
                    type="text"
                    value={copyProgresif[i].tarif4}
                    onChange={(val) =>
                      setCopyProgresif((prevCopyProgresif) => {
                        const updatedCopyProgresif = [...prevCopyProgresif];
                        updatedCopyProgresif[i] = {
                          ...updatedCopyProgresif[i],
                          tarif4: val.target.value,
                        };
                        return updatedCopyProgresif;
                      })
                    }
                    className="w-16"
                  />
                ) : (
                  data.tarif4
                )}
              </td>
              <td>
                {editProgresifData[i].status ? (
                  <input
                    type="text"
                    value={copyProgresif[i].beban_tetap}
                    onChange={(val) =>
                      setCopyProgresif((prevCopyProgresif) => {
                        const updatedCopyProgresif = [...prevCopyProgresif];
                        updatedCopyProgresif[i] = {
                          ...updatedCopyProgresif[i],
                          beban_tetap: val.target.value,
                        };
                        return updatedCopyProgresif;
                      })
                    }
                    className="w-16"
                  />
                ) : (
                  data.beban_tetap
                )}
              </td>
              <td>{data.ukuran} inch</td>
              <td>
                <button
                  className={`${
                    editProgresifData[i].status
                      ? "bg-[#ccf8fa] py-1 px-3 rounded-md text-gray-400 hover:text-white hover:bg-[#158087]"
                      : "bg-[#DCF4FF] py-1 px-3 rounded-md text-gray-400 hover:text-black hover:bg-[#b7e8ff]"
                  }`}
                  onClick={() => handleEdit(i)}
                >
                  {editProgresifData[i].status ? "Save" : "Edit"}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* edit popup */}
      {}
    </div>
  );
};

export default MetaProgresif;
