import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import axios from "axios";
import PuffLoader from "react-spinners/PuffLoader";

const MetaUser = () => {
  const [identitas, setIdentitas] = useState(true);
  const [filter, setFilter] = useState(false);

  const [valPdam, setValPdam] = useState("");
  const [val, setVal] = useState([]);
  const [loading, setLoading] = useState(false);

  const [dataUser, setDataUser] = useState([]);
  const [dataBill, setDataBills] = useState([]);
  const [listPdam, setListPdam] = useState([]);

  // idr
  function idr(number) {
    return number.toLocaleString("id-ID", {
      style: "currency",
      currency: "IDR",
    });
  }

  const controlFilter = () => {
    setFilter(!filter);
    if (filter === true) {
      setVal(valPdam);
      fetchingData(true);
      fetchingData(false);
    }
  };

  const radioPdam = (value) => {
    setValPdam(value.target.value);
  };

  async function fetchingData(ident) {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/lora/v1/data/super-meta-data`, {
        pdam: valPdam.toLowerCase(),
        askFor: "dataUsers",
        identitas: ident,
      })
      .then(function (response) {
        if (ident) {
          setDataUser(response.data);
          setLoading(false);
        } else {
          setDataBills(response.data);
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  useEffect(() => {
    async function getListPdam() {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/lora/v1/data/super-new-device`,
          {
            askList: true,
          }
        )
        .then(function (response) {
          setListPdam(response.data);
        })
        .catch(function (err) {
          console.error(err);
        });
    }
    getListPdam();
  }, []);

  return (
    <div className="bg-white h-full w-full rounded-lg">
      <div className="w-full h-[54px] rounded-t-lg flex justify-between px-[15px] items-center">
        <div className="flex gap-[15px] text-[12px] font-medium text-[#898989] relative">
          <div
            className="h-[30px] border border-[#38BDF8] rounded-lg flex items-center px-1 relative cursor-pointer active:border-white"
            onClick={controlFilter}
          >
            <Icon
              icon="material-symbols:filter-alt-outline"
              color="#38BDF8"
              width="18"
            />
            <input
              type="text"
              placeholder="Cari PDAM"
              readOnly
              value={val}
              className="w-[72px] outline-none cursor-pointer"
            />
          </div>
          <div
            className={`w-[100px] h-auto bg-white absolute top-9 ${
              filter ? "left-0" : "-left-40"
            } duration-500 p-2 rounded-lg border border-[#38BDF8] cursor-default`}
          >
            <p className="text-[10px] text-slate-400 border-b border-slate-400 mb-1">
              PDAM
            </p>
            {listPdam.map((pdam, i) => (
              <label key={i} className="flex gap-1 hover:text-[#38BDF8]">
                <input
                  type="radio"
                  name="pdam"
                  value={pdam.kota}
                  onChange={radioPdam}
                />
                {pdam.kota}
              </label>
            ))}
          </div>
          {/* <div className="relative">
            <input
              type="text"
              className="h-[30px] w-[250px] border border-[#38BDF8] peer rounded-lg flex items-center gap-[5px] pl-7 outline-none"
              placeholder="Cari berdadsarkan filter..."
            />
            <Icon
              icon="ic:sharp-search"
              color="#38BDF8"
              width="18"
              className="absolute top-1/2 -translate-y-1/2 left-2 peer-focus:scale-125"
            />
          </div> */}
        </div>
        <PuffLoader color="#38BDF8" size={40} loading={loading} />
        {/* identitas */}
        <div
          className="text-[12px] font-medium text-[#898989] h-[30px] w-[80px] flex items-center cursor-pointer group"
          onClick={() => setIdentitas(!identitas)}
        >
          <div className="w-full h-full relative overflow-hidden">
            <p
              className={`absolute ${
                identitas ? "left-0" : "left-14"
              } duration-500 top-1/2 -translate-y-1/2`}
            >
              Bills
            </p>
            <p
              className={`absolute ${
                identitas ? "-left-14" : "left-0"
              } duration-500 top-1/2 -translate-y-1/2`}
            >
              Identitas
            </p>
          </div>
          {identitas ? (
            <Icon
              icon="material-symbols:arrow-right-rounded"
              width="40"
              color="#38BDF8"
              className="group-hover:animate-jumpRight"
            />
          ) : (
            <Icon
              icon="material-symbols:arrow-right-rounded"
              width="40"
              color="#38BDF8"
              rotate={2}
              className="group-hover:animate-jumpLeft"
            />
          )}
        </div>
      </div>
      {identitas ? (
        <table className="w-full">
          <thead>
            <tr className="bg-[#DCF4FF] h-[40px] text-left border-b border-[#AAE4FE] text-[#474747]">
              <th className="pl-[15px]">Nama</th>
              <th>devEUI</th>
              <th>Kelompok</th>
              <th>Golongan</th>
              <th>Kecamatan</th>
              <th>PDAM</th>
              <th>Koordinat</th>
            </tr>
          </thead>
          <tbody>
            {dataUser.map((data, i) => (
              <tr
                key={i}
                className="h-[40px] border-b border-[#AAE4FE] font-medium text-[#898989]"
              >
                <td className="pl-[15px]">{data.nama}</td>
                <td>{data.deveui}</td>
                <td>{data.kelompok}</td>
                <td>{data.golongan}</td>
                <td>{data.kecamatan}</td>
                <td>{data.pdam}</td>
                <td>{[data.lat, ",", data.lng]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <table className="w-full">
          <thead>
            <tr className="bg-[#DCF4FF] h-[40px] text-left border-b border-[#AAE4FE] text-[#474747]">
              <th className="pl-[15px]">Nama</th>
              <th>devEUI</th>
              <th>Penggunaan &sup3;</th>
              <th>Tagihan</th>
              <th>Bulan</th>
              <th>Tahun</th>
              <th>Status</th>
              <th>Dibayar Pada</th>
            </tr>
          </thead>
          <tbody>
            {dataBill.map((data, i) => (
              <tr
                key={i}
                className="h-[40px] border-b border-[#AAE4FE] text-[#898989]"
              >
                <td className="pl-[15px]">{data.nama}</td>
                <td>{data.deveui}</td>
                <td>{data.penggunaan / 1000000}</td>
                <td>{idr(data.tagihan)}</td>
                <td>{data.bulan}</td>
                <td>{data.tahun}</td>
                <td>{data.status}</td>
                <td>
                  {data.dibayar_pada * 1 === 0
                    ? 0
                    : new Date(data.dibayar_pada * 1).toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default MetaUser;
