import React, { useEffect, useState } from "react";
import axios from "axios";
import { Icon } from "@iconify/react";

const NewPdam = () => {
  const [validate, setValidate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [seePass, setSeePass] = useState({
    pass: false,
    re_pass: false,
  });
  const [toaster, setToaster] = useState({
    pop: false,
    msg: "",
  });

  const [formData, setFormData] = useState({
    pdam: "",
    password: "",
    re_pass: "",
  });

  const handleFormData = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSimpan = async () => {
    if (validate === false) return;
    setLoading(true);
    try {
      const simpan = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/lora/v1/data/super-new-pdam`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setLoading(false);
      console.log(simpan.data);
      if (simpan.data.status === "success") {
        const updatedX = Object.fromEntries(
          Object.keys(formData).map((key) => [key, ""])
        );
        setFormData(updatedX);

        setToaster({
          ...toaster,
          pop: true,
          msg: simpan.data.msg,
        });
        setTimeout(() => {
          setToaster({
            ...toaster,
            pop: false,
          });
        }, 3000);
      } else if (simpan.data.status === "exist") {
        setToaster({
          ...toaster,
          pop: true,
          msg: simpan.data.msg,
        });
        setTimeout(() => {
          setToaster({
            ...toaster,
            pop: false,
          });
        }, 3000);
      } else {
        setToaster({
          ...toaster,
          pop: true,
          msg: simpan.data.msg,
        });
        setTimeout(() => {
          setToaster({
            ...toaster,
            pop: false,
          });
        }, 3000);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    setValidate(
      Object.values(formData).every((value) => value !== "") &&
        formData.password === formData.re_pass
    );
  }, [formData]);

  return (
    <div className="mt-[50px]">
      <div className="bg-white mt-5 flex flex-col p-5 items-center py-10">
        <p className="font-semibold">Tambah PDAM Baru</p>
        <div className="w-[50%] mt-5 flex flex-col gap-5 text-[14px] text-gray-500">
          <label>
            <p className="font-semibold">Nama PDAM (username)</p>
            <input
              type="text"
              placeholder="Masukkan nama pdam"
              className="border border-gray-300 w-full py-2 px-2 outline-[#38BDF8]"
              name="pdam"
              value={formData.pdam}
              onChange={handleFormData}
            />
          </label>
          <label className="relative">
            <p className="font-semibold">Password</p>
            <input
              type={`${seePass.pass ? "text" : "password"}`}
              placeholder="Masukkan password"
              className="border border-gray-300 w-full py-2 px-2 outline-[#38BDF8]"
              name="password"
              value={formData.password}
              onChange={handleFormData}
            />
            <div
              className="absolute right-3 top-1/2 opacity-80 cursor-pointer"
              onClick={() => setSeePass({ ...seePass, pass: !seePass.pass })}
            >
              {seePass.pass ? (
                <Icon icon="tabler:eye" width={25} />
              ) : (
                <Icon icon="tabler:eye-off" width={25} />
              )}
            </div>
          </label>
          <label className="relative">
            <p className="font-semibold">Ulangi Password</p>
            <input
              type={`${seePass.re_pass ? "text" : "password"}`}
              placeholder="Masukkan password kembali"
              className="border border-gray-300 w-full py-2 px-2 outline-[#38BDF8]"
              name="re_pass"
              value={formData.re_pass}
              onChange={handleFormData}
            />
            <div
              className="absolute right-3 top-1/2 opacity-80 cursor-pointer"
              onClick={() =>
                setSeePass({ ...seePass, re_pass: !seePass.re_pass })
              }
            >
              {seePass.re_pass ? (
                <Icon icon="tabler:eye" width={25} />
              ) : (
                <Icon icon="tabler:eye-off" width={25} />
              )}
            </div>
          </label>

          <button
            className={`${
              validate
                ? "bg-[#38BDF8] text-white hover:bg-[#26b0eb]"
                : "bg-gray-300 cursor-default"
            } py-3 rounded-lg text-[16px] font-semibold mt-5`}
            onClick={handleSimpan}
          >
            {loading ? "Loading..." : "Simpan"}
          </button>
          {toaster.pop && <p>{toaster.msg}</p>}
        </div>
      </div>
    </div>
  );
};

export default NewPdam;
