import Dashboard from './Dashboard'
import Pembayaran from './Pembayaran'
import logo from "../../assets/logo.png";
import { useState } from "react";
import { Icon } from "@iconify/react";
import Meta from './Meta';
import NewPdam from './NewPdam';

const Layout = () => {
  const [sideActive, setSideActive] = useState({ d: 1, p: 0, m:0, n:0 });
  const [toggle, setToggle] = useState(false);

  const active = {
    bg: "bg-[#158087] w-full rounded-l-[15px] text-white",
    color: "#474747",
  };

  function klikDashboard() {
    setSideActive({
      d: 1,
      p: 0,
      m: 0,
      n: 0
    });
  }

  function klikHistory() {
    setSideActive({
      d: 0,
      p: 1,
      m: 0,
      n: 0
    });
  }

  function klikMeta() {
    setSideActive({
      d: 0,
      p: 0,
      m: 1,
      n: 0
    });
  }

  function klikNewPdam() {
    setSideActive({
      d: 0,
      p: 0,
      m: 0,
      n: 1,
    });
  }

  // console.log(sideActive);

  function toggle_f() {
    setToggle(toggle ? false : true);
  }

  // logout
  const logout = () => {
    localStorage.removeItem('pdam');
    localStorage.removeItem('token');
    window.open('/', '_self')
  }

  return (
    <div className={`w-full flex relative pb-5 font-poppins`}>
      {/* sidebar */}
      <div
        id="sidebar"
        className={`w-[200px] h-screen bg-white text-[#474747] flex-shrink-0 fixed top-0 left-0 z-50  ${toggle ? 'inline' : 'invisible md:visible md:relative'}`}
      >
        <div className="ml-[45px] pt-10">
          <img src={logo} alt="logo" className="w-[63px] h-[43px]" />
        </div>
        <div className="flex flex-col items-center">
          <div>
            <hr className="bg-slate-400 w-[150px] h-[2px] mt-[24px] border-0" />
          </div>
          <div id="menu" className="mt-[74px] w-full pl-[10px]">
            <div
              className={`py-2 flex items-center cursor-pointer ${
                sideActive.d ? active.bg : ""
              }`}
              onClick={klikDashboard}
            >
              <Icon
                icon="material-symbols:dashboard-outline-rounded"
                className="w-[24px] h-[24px] ml-[18px]"
                color={sideActive.d ? "white" : active.color}
              />
              <p className="font-semibold text-[13px] ml-[7px]">Dashboard</p>
            </div>
            <div
              className={`py-2 flex items-center my-3 cursor-pointer ${
                sideActive.p ? active.bg : ""
              }`}
              onClick={klikHistory}
            >
              <Icon
                icon="tabler:report-money"
                color={sideActive.p ? "white" : active.color}
                className="w-[24px] h-[24px]  ml-[18px]"
              />
              <p className="font-semibold text-[13px] ml-[7px]">
                Pembayaran
              </p>
            </div>
            <div
              className={`py-2 flex items-center my-3 cursor-pointer ${
                sideActive.m ? active.bg : ""
              }`}
              onClick={klikMeta}
            >
              <Icon
                icon="carbon:bare-metal-server"
                color={sideActive.m ? "white" : active.color}
                className="w-[24px] h-[24px]  ml-[18px]"
              />
              <p className="font-semibold text-[13px] ml-[7px]">
                Meta Data
              </p>
            </div>
            <div
              className={`py-2 flex items-center my-3 cursor-pointer ${
                sideActive.n ? active.bg : ""
              }`}
              onClick={klikNewPdam}
            >
              <Icon
                icon="material-symbols:water-pump-outline-rounded"
                color={sideActive.n ? "white" : active.color}
                className="w-[24px] h-[24px]  ml-[18px]"
              />
              <p className="font-semibold text-[13px] ml-[7px]">
                PDAM Baru
              </p>
            </div>
          </div>
        </div>
        <div className="absolute bottom-4 left-1/2 -translate-x-1/2 cursor-pointer group p-2 w-10 h-10" title="log out" onClick={logout}>
          <Icon icon="material-symbols:door-front-rounded" width="26" className="group-hover:hidden"/>
          <Icon icon="material-symbols:door-open" width="26" className="group-hover:inline hidden"/>
        </div>
      </div>

      {/* header */}
      <div id="header" className="w-full mt-[18px] mx-3 md:mx-10 overflow-y-auto overflow-x-hidden">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-[#474747] font-bold uppercase">zuperr admin</h1>
          </div>
          <div className="md:invisible visible -mr-1">
            <Icon
              icon="ic:round-close"
              width="32"
              height="32"
              color="#474747"
              className={`cursor-pointer ${toggle ? "" : "hidden"}`}
              onClick={toggle_f}
            />
            <Icon
              icon="gg:menu-right-alt"
              width="32"
              height="32"
              color="#474747"
              className={`cursor-pointer ${toggle ? "hidden" : ""}`}
              onClick={toggle_f}
            />
          </div>
        </div>

        {/* content */}
        <div className={`${sideActive?.d ? 'inline' : 'hidden'}`}>
          <Dashboard />
        </div>
        <div className={`${sideActive?.p ? 'inline' : 'hidden'}`}>
          <Pembayaran />
        </div>
        <div className={`${sideActive?.m ? 'inline' : 'hidden'}`}>
          <Meta />
        </div>
        <div className={`${sideActive?.n ? 'inline' : 'hidden'}`}>
          <NewPdam />
        </div>
      </div>
    </div>
  );
};

export default Layout;
