import { Icon } from "@iconify/react";
import axios from "axios";
import { useEffect, useState } from "react";

import QrCode from "./../QrCode";
import Slip from "./Slip";
import PuffLoader from "react-spinners/PuffLoader";

import paid from "../../assets/paid.png";
import { useNavigate } from "react-router-dom";

const Pembayaran = () => {
  const navigation = useNavigate();
  const [action, setAction] = useState({
    default: 1,
    view: 0,
    pay: 0,
    index: -1,
  });
  const [payment, setPayment] = useState(true);
  const [input, setInput] = useState();
  const [dataBills, setDataBills] = useState([]);
  const [cariLoad, setCariLoad] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [listTahun, setListTahun] = useState([]);
  const [tahunSearch, setTahunSearch] = useState(0);
  const [loading, setLoading] = useState(false);

  const [payManual, setPayManual] = useState(false);
  const [payManualLoading, setPayManualLoading] = useState(false);
  const [terbayar, setTerbayar] = useState(false);

  const [historyDevSearch, setHistoryDevSearch] = useState("");

  const [generateLoading, setGenerateLoading] = useState(false);
  const [textQr, setTextQr] = useState("");

  const bulan = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  const pembayaranManual = async () => {
    console.log(dataBills[action.index]?.deveui);
    setPayManualLoading(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/lora/v1/data/payment`, {
        deveui: dataBills[action.index]?.deveui,
        bulan: dataBills[action.index].bulan,
        tahun: dataBills[action.index].tahun,
        token: process.env.REACT_APP_TOKEN,
      })
      .then((response) => {
        console.log(response.data);

        setTerbayar(true);
        setPayManualLoading(false);
        setTimeout(() => {
          setPayManual(false);
          setTerbayar(false);
          fetchingBay();
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const idr = (number) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(number);
  };

  const onInputChange = (val) => {
    setInput(val.target.value);
  };

  document.addEventListener("keydown", (event) => {
    if (event.key === "Enter") {
      fetchingBay();
    }
  });

  const handleButton = () => {
    setCariLoad(true);
    setHistoryDevSearch(input);
    fetchingBay();
  };

  const fetchingBay = async () => {
    setAction({
      default: 1,
      view: 0,
      pay: 0,
      index: -1,
    });
    try {
      const x = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/lora/v1/data/pembayaran-admin`,
        {
          deveui: input !== "" ? input : historyDevSearch,
          tahun: tahunSearch,
          token: localStorage.getItem("token"),
        }
      );

      if (x.data.length > 0) {
        setInput("");
        setDataBills(x.data);
        setCariLoad(false);
        // console.log(x.data);
      } else {
        setCariLoad(false);
        setNotFound(true);
        setTimeout(() => {
          setNotFound(false);
        }, 2000);

        if (x.data.msg === "jwt expired") {
          localStorage.removeItem("token");
          localStorage.removeItem("pdam");
          navigation("/", "_self");
        }
      }
    } catch (error) {
      setCariLoad(false);
      console.log(error.message);
    }
  };

  useEffect(() => {
    async function listingYears() {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/lora/v1/data/riwayat-list-tahun`
        )
        .then((list) => {
          setListTahun(list.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    listingYears();
  }, []);

  const cetakPdf = async (val) => {
    // ambil data progresif
    setLoading(true);
    const prog = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/lora/v1/data/riwayat-cetak-pdf`,
      {
        deveui: val.deveui,
        tahun: val.tahun,
        bulan: val.bulan,
      }
    );
    setLoading(false);
    Slip(prog.data);
  };

  // generate qrcode
  const generateQrCode = async () => {
    console.log("Generating QR");
    setGenerateLoading(true);
    try {
      const qrCode = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/lora/v1/data/payment/generateQr`,
        {
          nama: dataBills[action.index]?.nama,
          deveui: dataBills[action.index]?.deveui,
          namaPembayar: "super-admin",
          tlpPembayar: "0",
          amount: dataBills[action.index]?.tagihan,
          bulan: dataBills[action.index]?.bulan,
          tahun: dataBills[action.index]?.tahun,
        }
      );
      console.log(qrCode.data);
      if (qrCode.data.status === "success") {
        setGenerateLoading(false);
        setTextQr(qrCode.data.qr_string);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="text-[13px] md:flex md:gap-[20px] mt-[47px] relative">
      <div className="flex-1">
        <div className="flex text-[#474747]">
          <p className="font-medium">Cari pelanggan berdasarkan devEUI alat</p>
          <select
            className="ml-2 rounded-sm font-medium"
            onChange={(val) => setTahunSearch(val.target.value)}
          >
            {listTahun.map((thn, i) => (
              <option key={i}>{thn}</option>
            ))}
          </select>
        </div>
        <div className="flex drop-shadow-lg mt-[15px]">
          <input
            type="text"
            placeholder="input devEUI"
            className="w-full rounded-l-lg pl-3 focus:outline-none"
            value={input || ""}
            onChange={onInputChange}
          />
          <button
            className="font-semibold uppercase w-[100px] h-[35px] bg-[#158087] rounded-r-lg text-white hover:bg-[#2e979e]"
            onClick={handleButton}
          >
            {!cariLoad ? "cari" : "mencari..."}
          </button>
        </div>
        {notFound && (
          <p className="text-[11px] text-red-500 font-medium m-2 ">
            Data tidak ditemukan
          </p>
        )}
        {/* list hasil pencarian */}
        <div className="mt-[50px] flex flex-col gap-2">
          {dataBills.map((data, i) => (
            <div
              key={i}
              className="font-semibold bg-[#D9D9D9] h-[40px] w-full text-[13px] flex justify-between px-5 items-center "
            >
              <div className="flex gap-5 text-[#474747]">
                <p className="uppercase">{bulan[data.bulan - 1]}</p>
                <p>{data.tahun}</p>
              </div>
              <div className="flex gap-3">
                <div title="Download" onClick={() => cetakPdf(data)}>
                  {loading ? (
                    <PuffLoader color="#38BDF8" size={24} loading={loading} />
                  ) : (
                    <Icon
                      icon="ic:round-download"
                      width="24"
                      color="#474747"
                      className="cursor-pointer"
                    />
                  )}
                </div>
                <div title="Detail">
                  <Icon
                    icon="icon-park-outline:doc-detail"
                    width="24"
                    color="#474747"
                    className="cursor-pointer"
                    onClick={() =>
                      setAction({ default: 0, view: 1, pay: 0, index: i })
                    }
                  />
                </div>
                {data?.status === "paid" ? (
                  <div title="Sudah dibayar">
                    <Icon
                      icon="material-symbols:price-check-rounded"
                      width="24"
                      color="#474747"
                    />
                  </div>
                ) : (
                  <div title="Bayar">
                    <Icon
                      icon="majesticons:money-hand-line"
                      width="24"
                      color="#474747"
                      className="cursor-pointer"
                      onClick={() =>
                        setAction({ default: 0, view: 0, pay: 1, index: i })
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex-1 flex justify-center items-center h-full w-full mt-10 md:mt-0">
        <div className={`${action.default ? "inline" : "hidden"}`}>
          <p className={`text-3xl text-center font-bold opacity-30`}>
            cari dan pilih data untuk ditampilkan
          </p>
        </div>
        {/* details */}
        {action.view ? (
          <div className={`relative w-full h-full ml-10 mt-8 font-semibold`}>
            <div className="opacity-25 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/4 z-0">
              {dataBills[action.index]?.status === "paid" ? (
                <p className="text-[150px] rotate-45 text-green-400 select-none">
                  PAID
                </p>
              ) : (
                <p className="text-[130px] rotate-45  text-red-400 select-none">
                  UNPAID
                </p>
              )}
            </div>
            <div id="view z-50">
              <div className="flex gap-2 font-normal relative">
                <div>
                  <p>Bulan</p>
                  <p>Nama Pelanggan</p>
                  <p>DevEUI Pelanggan</p>
                  <p>Alamat Pelanggan</p>
                  <p>Kategori Pelanggan</p>
                </div>
                <div>
                  <p>
                    : {bulan[dataBills[action.index]?.bulan - 1]}{" "}
                    {dataBills[action.index]?.tahun}
                  </p>
                  <p>: {dataBills[action.index]?.nama}</p>
                  <p>: {dataBills[action.index]?.deveui}</p>
                  <p>
                    : {dataBills[action.index]?.kecamatan}{" "}
                    {dataBills[action.index]?.pdam}
                  </p>
                  <p>
                    : Kelompok {dataBills[action.index]?.kelompok} Golongan{" "}
                    {dataBills[action.index]?.golongan}
                  </p>
                </div>
              </div>
              <div className="mt-5 relative">
                <p className="text-lg">Tagihan</p>
                <div className="flex justify-between ml-5">
                  <table className="w-full border-collapse">
                    <thead className="text-left">
                      <tr className="border-b border-slate-500">
                        <th className="py-2">Keterangan</th>
                        <th>Nilai</th>
                      </tr>
                    </thead>
                    <tbody className="font-normal">
                      <tr className="border-b border-slate-500">
                        <td className="py-1">Jumlah pemakaian {"(m3)"}</td>
                        <td>{dataBills[action.index]?.penggunaan / 1000}</td>
                      </tr>
                      {/* <tr className="border-b border-slate-500">
                        <td className="py-1">Harga 10 m3 pertama</td>
                        <td>{idr(2569)}</td>
                      </tr>
                      <tr className="border-b border-slate-500">
                        <td className="py-1">Harga 10 m3 kedua</td>
                        <td>{idr(3500)}</td>
                      </tr>
                      <tr className="border-b border-slate-500">
                        <td>Biaya tetap</td>
                        <td>{idr(5000)}</td>
                      </tr>
                      <tr className="border-b border-slate-500">
                        <td className="py-1">Biaya denda</td>
                        <td>0</td>
                      </tr> */}
                      <tr className="border-b border-slate-500 font-bold">
                        <td className="py-1">Total tagihan</td>
                        <td>{idr(dataBills[action.index]?.tagihan)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className={`${action.pay ? "inline" : "hidden"} w-full`}>
          <div className="flex justify-around font-semibold text-[14px]">
            <p
              onClick={() => setPayment(!payment)}
              className={`${
                payment ? "text-[#474747]" : "text-[#9d9d9d]"
              } cursor-pointer`}
            >
              Manual
            </p>
            <p
              onClick={() => setPayment(!payment)}
              className={`${
                payment ? "text-[#9d9d9d]" : "text-[#474747]"
              } cursor-pointer`}
            >
              Online
            </p>
          </div>
          <div>
            {payment ? (
              <div className="w-full h-full flex justify-center">
                <div className="w-1/2 h-[250px] bg-[#D9D9D9] rounded-lg mt-20 flex flex-col justify-center items-center gap-5">
                  <Icon
                    icon="material-symbols:warning-outline-rounded"
                    width="50"
                    color="#ca8a04"
                  />
                  <p className="text-center">
                    Pastikan anda menerima uang sejumlah{" "}
                    <span className="font-bold">
                      {idr(dataBills[action.index]?.tagihan)}{" "}
                    </span>
                    dari pelanggan tersebut
                  </p>
                  <button
                    className="w-1/2 h-[30px] rounded-lg text-white bg-[#158087] hover:bg-[#179ca5]"
                    onClick={() => setPayManual(!payManual)}
                  >
                    Bayar
                  </button>
                </div>
              </div>
            ) : (
              <div className="w-full h-full flex flex-col items-center">
                {textQr === "" ? (
                  <div className="w-1/2 h-[250px] bg-[#D9D9D9] rounded-lg mt-20 flex flex-col justify-center items-center gap-5">
                    <div className="flex w-full justify-between px-5 text-xs">
                      <div>
                        <p>Amount</p>
                        <p>Nama</p>
                        <p>No tlp</p>
                      </div>
                      <div className="flex flex-col text-right">
                        <p>{dataBills[action.index]?.tagihan}</p>
                        <p>{dataBills[action.index]?.nama}</p>
                        <p>{dataBills[action.index]?.telp}</p>
                      </div>
                    </div>
                    <Icon icon="bi:qr-code-scan" width="70" color="#ca8a04" />
                    <button
                      className="w-1/2 h-[30px] bg-[#158087] rounded-lg text-white hover:bg-[#179ca5]"
                      onClick={generateQrCode}
                    >
                      {generateLoading ? "Loading..." : "Generate QR"}
                    </button>
                  </div>
                ) : (
                  <div className="mt-10">
                    <QrCode qrString={textQr} />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {payManual && (
        <div className="flex">
          <div className="absolute top-0 left-0 w-full h-[600px] opacity-80 bg-black z-40" />
          <div className="absolute top-[25%] left-1/2 -translate-x-1/2 w-[400px] h-[350px] rounded-lg bg-white  z-50">
            {terbayar ? (
              <div className="h-full flex flex-col items-center justify-center gap-5">
                <img
                  alt="paid"
                  src={paid}
                  className="w-[150px] h-[150px] animate-jump"
                />
                <p className="text-[30px] font-bold text-[#687065] stroke-black">
                  Paiment <span className="text-[#5fcf37]">Success</span>
                </p>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center gap-10 py-10">
                <div className="text-[#3f3f3f]">
                  <p className="font-medium mb-10 text-center">
                    Anda yakin! akan melakukan pembayaran manual terhadap...?
                  </p>
                  <div className="pl-[25%]">
                    <p>
                      Nama:{" "}
                      <span className="font-semibold">
                        {dataBills[action.index]?.nama}
                      </span>
                    </p>
                    <p>
                      Deveui:{" "}
                      <span className="font-semibold">
                        {dataBills[action.index]?.deveui}
                      </span>
                    </p>
                    <p>
                      Bulan:{" "}
                      <span className="font-semibold">
                        {bulan[dataBills[action.index]?.bulan - 1]}
                      </span>
                    </p>
                    <p>
                      Tahun:{" "}
                      <span className="font-semibold">
                        {dataBills[action.index]?.tahun}
                      </span>
                    </p>
                    <p>
                      Tagihan:{" "}
                      <span className="font-semibold">
                        {idr(dataBills[action.index]?.tagihan)}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="flex gap-5">
                  <button
                    className="hover:text-red-500"
                    onClick={() => setPayManual(false)}
                  >
                    tidak jadi
                  </button>
                  <button
                    className="bg-[#158087] hover:bg-[#179ca5] rounded-lg py-2 px-20 uppercase font-semibold text-white"
                    onClick={pembayaranManual}
                  >
                    {payManualLoading ? "proses..." : "ya tentu"}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Pembayaran;
